import React from "react";
import { CircularProgress, Stack } from "@mui/material";

export default function StackWithCircularProgres() {
  return (
    <Stack sx={{ width: "100%", alignItems:"center", mt:2 }} spacing={2}>
      <CircularProgress color="secondary" sx={{ margin: "0 auto" }} />
    </Stack>
  );
}
