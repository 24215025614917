export const dashboardTrans = {
  subscription: {
    active: "Twój abonament jest aktywny",
    error: "Konto nie posiada aktywnego abonamentu!",
    extend: "Przedłuż plan",
    using_trial: "Korzystasz z darmowego planu próbnego",
    left_days: "Do końca Twojej subskrypcji pozostało {{leftDays}} dni",
    left_days_in_current: "Do końca obecnego planu pozostało {{leftDays}} dni",
    plan_name: "Plan {{type}}",
    choose_plan: "Wybierz plan",
    plans: {
      standard: "Standard",
      starter: "Starter",
      premium: "Premium",
      monthPlan: "Miesięczny",
      quarterPlan: "Kwartalny",
      yearPlan: "Roczny",
      trial: "Darmowy okres próbny",
      month: "Miesiąc",
      three_months: "3 miesiące",
      twelve_months: "12 miesięcy",
    },
    plan_is_ending: "Twój abonament dobiega końca",
    using_plan: "Obecnie korzystasz z planu {{type}}",
  },
  welcome_back: "Witaj ponownie",
  nice_to_see_you: "Miło Cię widzieć",
  risk_in_parties: "Ocena ryzyka w podmiotach",
  risk_in_transactions: "Ocena ryzyka w transakcjach",
  parties_status: "Statusy podmiotów",
  transactions_status: "Statusy transakcji",
  number_of_evaluations: "Liczba ocen",
  no_data: "Brak danych",
  
};
